/*----------------------------------------------------------------------------*
	$FORMULARIOS
*----------------------------------------------------------------------------*/



/* ^Form global
-----------------------------------------------------------------------------*/


	$('.js-form').each(function(index, element) {

		var form_global = $(element),
			form_btn = form_global.find('.js-btn-send'),
			form_action = form_global.attr('data-action');

		// Validar campos del formulario Newsletter
		form_global.validate({
			ignore: ".ignore, :disabled",
			errorClass: 'o-form-error',
			errorPlacement: function(error, element) {
				error.appendTo(element.parents('.js-show-error'));
			},
			// rules: {
			// },
			submitHandler: function(form) {

				form_btn.attr("disabled",true);

				form_data = form_global.serialize();

				// Ocultar las notificaciones al presionar boton enviar
				$('.js-notificacion').slideUp(400);

				// Mostrar cargador
				open_loading();

				$.post(form_action, form_data,
					function(data)
						{

							form_btn.removeAttr('disabled');

							// Si se envió correctamente
							if(data.match('1') == '1'){

								$('.js-notificacion').slideDown(400);

								$('.o-form-field, .o-form-field--textarea, select').val("");
								$('input[type="checkbox"], input[type="radio"]').attr("checked", false);

								// Ocultar cargador
								close_loading();

							}

						}
				);
				// Fin - $.post()

			}
		});

	});


/* ^Fin - Form global
-----------------------------------------------------------------------------*/

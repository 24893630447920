/*----------------------------------------------------------------------------*
	$SLIDERS.js
*----------------------------------------------------------------------------*/


/* ^Slider Caracteristicas
-----------------------------------------------------------------------------*/


	$('.js-slider-1').each(function() {
		let $this = $(this);
		let nav = this.id + '-nav-dots';
		let btns = this.id + '-nav-btns';
		let idSliderText = this.id + '-text';

		$(this).slick({
			appendDots: '#'+nav,
			infinite: true,
			dots: true,
			// fade: true,
			arrows: true,
			// adaptiveHeight: true,
			easing: 'easeInOutQuart',
			asNavFor: '#'+idSliderText,
			prevArrow: '<div class="slick-prev  slick-arrow  icon-arrow-left3"></div>',
			nextArrow: '<div class="slick-next  slick-arrow  icon-arrow-right3"></div>',
			responsive: [
				{
					breakpoint: 991,
					settings: {
						easing: 'easeOutCubic',
					}
				},
			]
		});

		$('#'+btns + ' .js-next').on('click', function(e) {
			e.preventDefault();
			$this.slick('slickNext');
		});

		$('#'+btns + ' .js-prev').on('click', function(e) {
			e.preventDefault();
			$this.slick('slickPrev');
		});


	});



	$('.js-slider-1-text').each(function() {

		let idSlider = this.id;

		$('#'+idSlider).slick({
			infinite: true,
			dots: false,
			// fade: true,
			touchMove: false,
			draggable: false,
			accessibility: false,
			adaptiveHeight: true,
			arrows: false,
			easing: 'easeInOutQuart',
			prevArrow: '<div class="slick-prev  slick-arrow  icon-arrow-left2"></div>',
			nextArrow: '<div class="slick-next  slick-arrow  icon-arrow-right2"></div>',
			responsive: [
				{
					breakpoint: 991,
					settings: {
						easing: 'easeOutCubic',
					}
				},
			]
		});

	});

/* ^Fin - Slider Caracteristicas
-----------------------------------------------------------------------------*/





/* ^Slider capturas
-----------------------------------------------------------------------------*/

	$('.js-slide-3').slick({
		appendArrows: '#slide-3__arrows',
		appendDots: '#slide-3__dots',
		centerMode: true,
		centerPadding: '0px',
		infinite: true,
		dots: false,
		slidesToShow: 3,
		slidesToScroll: 3,
		// fade: true,
		arrows: true,
		// adaptiveHeight: true,
		easing: 'easeInOutQuart',
		prevArrow: '<div class="slick-prev  slick-arrow  icon-circle-left2"></div>',
		nextArrow: '<div class="slick-next  slick-arrow  icon-circle-right2"></div>',
		responsive: [
			{
				breakpoint: 992,
				settings: {
					easing: 'easeOutCubic',
					centerMode: false,
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 769,
				settings: {
					easing: 'easeOutCubic',
					centerMode: false,
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});


	$('.c-slide-3__item').on('click', function(e) {
		e.preventDefault();

		slideIndex = $(this).attr('data-slick-index');

		$('.js-slide-3').slick('slickGoTo', slideIndex);

	});

/* ^Fin - Slider capturas
-----------------------------------------------------------------------------*/





/* ^Slider testimonios
-----------------------------------------------------------------------------*/

	$('.js-slide-4').slick({
		appendArrows: '#slide-4__arrows',
		appendDots: '#slide-4__dots',
		centerMode: true,
		centerPadding: '0px',
		infinite: true,
		dots: false,
		slidesToShow: 3,
		slidesToScroll: 3,
		// fade: true,
		arrows: true,
		// adaptiveHeight: true,
		easing: 'easeInOutQuart',
		prevArrow: '<div class="slick-prev  slick-arrow  icon-circle-left2"></div>',
		nextArrow: '<div class="slick-next  slick-arrow  icon-circle-right2"></div>',
		responsive: [
			{
				breakpoint: 992,
				settings: {
					easing: 'easeOutCubic',
					centerMode: false,
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 769,
				settings: {
					easing: 'easeOutCubic',
					centerMode: false,
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});


	$('.c-slide-4__item').on('click', function(e) {
		e.preventDefault();

		slideIndex = $(this).attr('data-slick-index');

		$('.js-slide-4').slick('slickGoTo', slideIndex);

	});

/* ^Fin - Slider testimonios
-----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*
	$MENUS.js
*----------------------------------------------------------------------------*/


/*	^Detectar ancho de scroll
-----------------------------------------------------------------------------*/

	var scrollDiv = document.createElement("div");
	scrollDiv.style.cssText = 'width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;';

	document.body.appendChild(scrollDiv);

	scrollbarSize = scrollDiv.offsetWidth - scrollDiv.clientWidth;
	document.body.removeChild(scrollDiv);


/*	^Fin - Detectar ancho de scroll
-----------------------------------------------------------------------------*/





/* ^Open menú movil
-----------------------------------------------------------------------------*/

	$('.js-open-menu-mobile').on('click', function(e) {
		e.preventDefault();

		let openID = $(this).attr('data-open-id');

		if ( !$(openID).hasClass('open') ) {

			$(this).removeClass('icon-menu7').addClass('icon-cross2');

			$(openID).css('display', 'block');


			setTimeout(function(){
				$(openID).addClass('open');

				$(document).on('click.menu', function(e) {
					$('.js-open-menu-mobile').trigger('click');
				});

			},40);




		} else {

			$(this).removeClass('icon-cross2').addClass('icon-menu7');

			$(openID).removeClass('open');

			setTimeout(function(){
				$(openID).css('display', 'none');
			},210);

			$(document).off('click.menu');

		}

	});


/* ^Fin - Open menú movil
-----------------------------------------------------------------------------*/





/* ^Modificar header al hacer scroll
-----------------------------------------------------------------------------*/

	let classHeader = $('.c-header');

	let verifyScroll = true;
	$(window).scroll(function(){

		let scrollTop = $(this).scrollTop();

		if( scrollTop > 100  && verifyScroll ){

			classHeader.addClass('c-header--scroll');
			classHeader.removeClass('c-header');

			setTimeout(function(){
				classHeader.addClass('open-1');

				setTimeout(function(){
					classHeader.addClass('open-2');
				},10);

			},10);

			verifyScroll = false;


		} else if ( scrollTop < 100 && !verifyScroll ) {


			classHeader.removeClass('open-2');

			setTimeout(function(){
				classHeader.removeClass('open-1');

				setTimeout(function(){
					$('.c-header--scroll').addClass('c-header');
					$('.c-header--scroll').removeClass('c-header--scroll');
				},10);

			},10);

			verifyScroll = true;
		}
	});

/* ^Fin - Modificar header al hacer scroll
-----------------------------------------------------------------------------*/





/* ^Navegar sección de página
-----------------------------------------------------------------------------*/

	$.scrollIt({
		topOffset: -65
	});

/* ^Fin - Navegar sección de página
-----------------------------------------------------------------------------*/

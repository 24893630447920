/*----------------------------------------------------------------------------*
	$POPUPS.JS
*----------------------------------------------------------------------------*/


/* ^Loading modal
-----------------------------------------------------------------------------*/

	function open_loading() {
		$('.js-menu-mobile-overlay').css('z-index',5000).fadeIn(200);
		$('.js-loader').css('z-index',5001).fadeIn(400);
	}

	function close_loading() {
		$('.js-menu-mobile-overlay').fadeOut(200, function(){
			$(this).css('z-index',100)
		});
		$('.js-loader').fadeOut(400, function(){
			$(this).css('z-index',101)
		});
	}


/* ^Fin - Loading modal
-----------------------------------------------------------------------------*/
